import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!username || !password) {
      toast.error("Please enter both email and password");
      return;
    }
  
    try {
      const response = await axios.post("http://3.110.101.115:8066/master/admin/login", {
        email: username, // Send as "email" instead of "username"
        password,
      });
  
      console.log(response)
      console.log(response.data.token)
      if (response.data) {
        localStorage.setItem("jwtToken", response.data.responseData.token);
        toast.success("Admin logged in successfully");
        navigate("/dashboard");
      } else {
        toast.error("Login failed");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          toast.error("Invalid email or password");
        } else {
          toast.error(`Login failed: ${error.response.statusText}`);
        }
      } else if (error.request) {
        toast.error("Network error. Please try again later.");
      } else {
        toast.error("An unexpected error occurred");
      }
      console.error("Error logging in:", error);
    }
  };
  

  return (
    <div className="md:flex h-screen overflow-hidden bg-slate-200">
      <ToastContainer />
      <div className="md:flex w-full h-full shadow-md rounded overflow-hidden">
        <div className="flex relative md:absolute md:top-0 md:right-0 md:w-2/4 lg:w-3/5">
          <img
            src="/pexels-roman-odintsov-6898859[1].jpg"
            alt="Background"
            className="w-full h-auto md:w-full md:h-screen object-cover"
          />
          <h1
            className="ps-8 md:hidden z-50 absolute bottom-0 md:bottom-20 text-3xl font-bold -mb-1"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Welcome Back
          </h1>
        </div>
        
        <div className="ps-5 pt-10 w-full lg:w-1/3 lg:flex justify-between md:justify-center items-center">
          <form className="w-full md:mt-48 lg:mt-0 max-w-xs flex flex-col items-start ml-4 relative lg:relative">
            <h1
              className="md:text-xl hidden md:-mt-16 md:block z-50 absolute lg:static lg:text-4xl font-extrabold mb-0.5"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Welcome Back
            </h1>
            <h4
              className="md:text-xs -mt-8 text-xs font-bold mb-6 whitespace-nowrap ps-0.5 lg:mt-1"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Log In to Connect with Real Reviews from Real People
            </h4>
            <div className="mb-4 w-full">
              <label
                className="flex justify-start text-gray-700 text-xs font-bold mb-2"
                htmlFor="username"
              >
                Email
              </label>
              <input
                className="shadow appearance-none border rounded-lg w-full max-w-md py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="User Name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-4 w-full">
              <label
                className="flex justify-start text-gray-700 text-xs font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                className="shadow appearance-none border rounded-lg w-full max-w-md py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center mb-6">
              <input
                id="rememberMe"
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="form-checkbox h-4 w-4 text-blue-600"
              />
              <label
                htmlFor="rememberMe"
                className="ml-2 text-sm text-gray-700"
              >
                Remember Me
              </label>
            </div>
            <div className="w-full">
              <button
                className="bg-black hover:bg-gray-800 text-white font-bold py-3 px-4 rounded-xl w-full focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleLogin}
              >
                Login
              </button>
            </div>
          </form>
        </div>
        <div className=" absolute bottom-0 left-0 w-full lg:w-auto lg:left-28 lg:text-left  text-center text-xs text-gray-600 md:py-2">
          <a href="">Powered by Teqbae Innovations and Private Limited</a>
        </div>
        <div className="absolute bottom-12 right-12 text-black hidden lg:block">
          <div className="text-base font-bold font-custom -ms-12">
            Discover honest reviews
          </div>
          <div className="text-base font-secondary">
            Your Guide to the Right Choices!
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
