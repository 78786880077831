import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Feedback = () => {
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    occupation: '',
    email: '',
    would_recommend: null,
    suggestion: '',
    likes: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'age' ? Number(value) : value
    });
  };

  const handleRecommendChange = (e) => {
    setFormData({
      ...formData,
      would_recommend: e.target.value === 'true' ? true : false
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.learnyn.in/harvestbox/createfb', formData);
      console.log(response.data);
      console.log(formData);

      // Handle success
      setFormData({
        name: '',
        age: '',
        occupation: '',
        email: '',
        would_recommend: null,
        suggestion: '',
        likes: ''
      });
      toast.success('Feedback submitted successfully');
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
      toast.error('Error submitting feedback');
    }
  };

  return (
    <div className='justify-center bg-slate-200 min-h-screen flex flex-col items-center pt-8 px-'>
      {/* Text Section */}
      <div className='text-left mb-4'>
        <h1 className='text-xl mb-1' style={{ fontFamily: 'Poppins' }}>
          <span className='font-bold'>Add Your </span>
          <span className='text-bold-xs'>Review</span>
        </h1>
        <p className='text-xs text-gray-600' style={{ fontFamily: 'Poppins' }}>
          Share Your Experience With Us!
        </p>
      </div>

      {/* Review Form */}
      <div className='bg-slate-200 rounded-bl-3xl rounded-br-3xl rounded-lg shadow-lg w-full max-w-md'>
        <div className='rounded-bl-3xl rounded-br-3xl bg-black rounded-t-3xl'>
          <div className='bg-black text-white pl-3 py-3 rounded-tr-2xl rounded-tl-2xl'>
            <h2 className='ps-2 text-xs font-semibold text-left'>Review Form</h2>
          </div>

          <form className='p-6 pb-12 rounded-bl-2xl rounded-br-2xl bg-white rounded-tr-3xl rounded-tl-3xl' onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 gap-2 mb-2'>
              <div className='w-full'>
                <label htmlFor='name' className='pb-2 block text-xs font-medium text-black text-left'>
                  Name
                </label>
                <input
                  id='name'
                  name='name'
                  type='text'
                  placeholder='Your Name'
                  className='pb-2 mt-1 block w-full border-b border-gray-300 bg-transparent focus:outline-none focus:border-transparent sm:text-xs'
                  value={formData.name}
                  onChange={handleChange}



                  
                />
              </div>

              <div className='w-full'>
                <label htmlFor='email' className='pb-2 block text-xs font-medium text-black text-left'>
                  Email ID
                </label>
                <input
                  id='email'
                  name='email'
                  type='email'
                  placeholder='Your Email'
                  className='pb-2 mt-1 block w-full border-b border-gray-300 bg-transparent focus:outline-none focus:border-transparent sm:text-xs'
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div className='grid grid-cols-1 md:grid-cols-2 gap-2 mb-2'>
                <div>
                  <label htmlFor='age' className='pb-2 block text-xs font-medium text-black text-left'>
                    Age
                  </label>
                  <input
                    id='age'
                    name='age'
                    type='number'
                    placeholder='Your Age'
                    className='pb-2 mt-1 block w-full border-b border-gray-300 bg-transparent focus:outline-none focus:border-transparent sm:text-xs'
                    value={formData.age}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor='occupation' className='pb-2 block text-xs font-medium text-black text-left'>
                    Occupation
                  </label>
                  <input
                    id='occupation'
                    name='occupation'
                    type='text'
                    placeholder='Your Occupation'
                    className='pb-2 mt-1 block w-full border-b border-gray-300 bg-transparent focus:outline-none focus:border-transparent sm:text-xs'
                    value={formData.occupation}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className='pb-3 mb-2'>
              <label className='pb-3 block text-xs font-medium text-black text-left'>
                Would you suggest our product to friends or family?
              </label>
              <div className='flex items-center space-x-4'>
                <div className='flex items-center'>
                  <input
                    id='recommend-yes'
                    name='would_recommend'
                    type='radio'
                    value='true'
                    className='form-radio text-black border-black'
                    style={{ accentColor: 'black' }}
                    checked={formData.would_recommend === true}
                    onChange={handleRecommendChange}
                  />
                  <label htmlFor='recommend-yes' className='ml-2 text-xs text-black'>Yes</label>
                </div>
                <div className='flex items-center'>
                  <input
                    id='recommend-no'
                    name='would_recommend'
                    type='radio'
                    value='false'
                    className='form-radio text-black border-black'
                    style={{ accentColor: 'black' }}
                    checked={formData.would_recommend === false}
                    onChange={handleRecommendChange}
                  />
                  <label htmlFor='recommend-no' className='ml-2 text-xs text-black'>No</label>
                </div>
              </div>
            </div>

            <div className='mb-4'>
              <label htmlFor='suggestion' className='pb-4 block text-xs font-medium text-black text-left'>
                Suggestion to improve our product
              </label>
              <input
                id='suggestion'
                name='suggestion'
                type='text'
                placeholder='Your Answer'
                className='pb-1 mt-1 block w-full border-b border-gray-300 bg-transparent focus:outline-none focus:border-transparent sm:text-xs'
                value={formData.suggestion}
                onChange={handleChange}
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='likes' className='pb-4 pt-2 block text-xs font-medium text-black text-left'>
                What did you like about our product?
              </label>
              <input
                id='likes'
                name='likes'
                type='text'
                placeholder='Your Answer'
                className='pb-1 mt-1 block w-full border-b border-gray-300 bg-transparent focus:outline-none focus:border-transparent sm:text-xs'
                value={formData.likes}
                onChange={handleChange}
              />
            </div>

            <div className='mt-6 flex justify-end'>
              <button
                type='submit'
                className='px-6 py-1.5 bg-black text-white text-xs rounded-md shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-black'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Feedback;
