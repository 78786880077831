import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const Dashboard = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);

  // Fetch feedback data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.learnyn.in/harvestbox/getallfb', {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
        });
        setRows(response.data.responsedata); // Assuming response.data contains the feedback array
        console.log(response.data.responsedata);
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Response error:', error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Request error:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error:', error.message);
        }
        console.error('Error fetching feedback data:', error.config);
      }
    };

    fetchData();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="px-10 bg-slate-200 h-screen font-poppins">
      <h1 className="pt-10 flex justify-start text-2xl" style={{ fontFamily: 'Poppins, sans-serif' }}>
        <span className="font-bold">Your&nbsp;</span> <span className="font-normal">Reviews</span>
      </h1>
      <div className="absolute top6 left-10">
        <p className="text-sm text-gray-600 mt-1" style={{ fontFamily: 'Poppins, sans-serif' }}>
          Explore our Reviews!
        </p>
      </div>
      <div className="mt-20">
        <TableContainer component={Paper}>
          <Table className="bg-slate-200" sx={{ minWidth: 750 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow className="bg-black rounded-full ">
                <TableCell
                  className="rounded-tl-2xl"
                  style={{ color: 'white', fontFamily: 'Poppins, sans-serif' }}
                  align="center"
                >
                  Name
                </TableCell>
                <TableCell style={{ color: 'white', fontFamily: 'Poppins, sans-serif' }} align="center">
                  Age
                </TableCell>
                <TableCell style={{ color: 'white', fontFamily: 'Poppins, sans-serif' }} align="center">
                  Occupation
                </TableCell>
                <TableCell style={{ color: 'white', fontFamily: 'Poppins, sans-serif' }} align="center">
                  Email ID
                </TableCell>
                <TableCell style={{ color: 'white', fontFamily: 'Poppins, sans-serif' }} align="center">
                  Referable
                </TableCell>
                <TableCell style={{ color: 'white', fontFamily: 'Poppins, sans-serif' }} align="center">
                  Suggestions
                </TableCell>
                <TableCell className="rounded-tr-2xl" style={{ color: 'white', fontFamily: 'Poppins, sans-serif' }} align="center">
                  Excitement
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rows.length > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : []).map((row, index) => (
                <TableRow className="bg-white" key={index}> {/* Use a unique identifier for key */}
                  <TableCell align="center" component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.age}</TableCell>
                  <TableCell align="center">{row.occupation}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">
                    <span style={{ color: row.would_recommend ? 'green' : 'red', textTransform: 'uppercase' }}>
                      {row.would_recommend ? 'Yes' : 'No'}
                    </span>
                  </TableCell>
                  <TableCell align="center">{row.suggestion}</TableCell>
                  <TableCell align="center">{row.likes}</TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={7}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Dashboard;
